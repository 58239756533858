<template>
	<div class="event-details ml-12">
		<vs-row vs-justify="center">
			<vs-row vs-justify="center">
				<vs-col type="flex" vs-justify="center" vs-align="center" class="ml-2 mr-2 xs:w-1/1 sm:w-3/4 lg:w-1/1">
					<div class="flex flex-wrap mt-5 mb-5 event-user">
						<!-- <router-link :to="`/`" class="text-black">
							<feather-icon icon="ArrowLeftIcon" class="inline-block mr-5 pt-4" svgClasses="w-12 h-12" />
						</router-link> -->
						<h6>{{$t('Invoice Number')}} : {{ invoice && invoice.transaction_number ? invoice.transaction_number : '' }}</h6>
					</div>
				</vs-col>
			</vs-row>
			<vs-col type="flex" vs-justify="center" vs-align="center" class="ml-2 mr-2 xs:w-1/1 sm:w-3/4 lg:w-1/1">
				<vs-card class="cardx relative event-user">
					<div v-if="(eventData && eventData.feature_image) === null" slot="media" class="thumbnail-event">
						<div class="initial-event">{{ generateInitial(eventData) }}</div>
					</div>
					<div v-else slot="media">
						<div class="initial-event"><img :src="eventData.feature_image" alt="img" class="responsive card-img-top-details" /></div>
					</div>
				</vs-card>
				<vs-row vs-justify="center">
					<vs-col type="flex">
						<!-- event detail -->
						<div class="event-user">
							<h5>{{$t('Thank you for your purchase for')}}</h5>
							<div class="title-user-events">{{ eventData ? eventData.name : '' }}</div>
							<div>{{dateFormat(eventData ? eventData.start_date : '')}}</div>
						</div>
						<!-- event price -->
						<div class="mt-12 event-user">
							<label class="text-grey">{{$t('Event Price')}}</label>
							<h1>{{ priceEvent(eventData ? eventData.is_paid : '') }}</h1>
							<div class="mt-3">{{$t('This is a proof of purchased')}}</div>
						</div>
						<vs-row vs-type="flex" vs-justify="flex-end" class="event-user">
							<vs-button @click="seeMyEvent()" class="mr-5" color="grey">{{$t('See My Event')}}</vs-button>
							<vs-button @click="openPreview()" color="#00A1ED">{{$t('Go to Event')}}</vs-button>
						</vs-row>
					</vs-col>
				</vs-row>
			</vs-col>
		</vs-row>
	</div>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
// import dayjs from 'dayjs';
import eventsApi from '@/api/event';
import transactionsApi from '@/api/transaction';
import {
	dateFormat,
} from '@/lib/helper';

export default {
	name: 'UserEventDetails',
	components: {
	},
	data() {
		return {
			isFetching: false,
			eventData: null,
			isSidebarActive: false,
			statisticData: null,
			dataImg: null,
			dataIsPublish: false,
			dataPrice: '',
			dataIsPaid: '',
			dataDescription: '',
			dataStart: '',
			dataEnd: '',
			dataLocation: '',
			dataCode: '',
			allowAccessCode: true,
			confirmPurchaseDialog: false,
			showDialogCompletedPurchase: false,
			showDialogStripePurchase: false,
			configDateTimePicker: {
				enableTime: true,
				dateFormat: 'Y-m-d H:i',
				time_24hr: true,
        minDate: 'today',
			},
			// invoice
			invoice: null,
			isFetchingInvoice: false,
			// stripe
			stripe: '',
			elements: '',
			card: '',
			name: '',
			addPaymentStatus: 0,
			addPaymentStatusError: '',
			clientSecret: '',
			checkoutTransaction: [],
		}
	},
	computed: {
		id() {
			return this.$route.params.id;
		},
	},
	methods: {
		getInvoice() {
			this.$vs.loading();
			this.isFetchingInvoice = true;
			const callback = (response) => {
				const invoice = response.data;
				this.invoice = invoice;
				this.fetchDetails();
				this.isFetchingInvoice = false;
				this.$vs.loading.close();
			};
			const errorCallback = () => {
				this.isFetchingInvoice = false;
				this.$vs.loading.close();
			};
			transactionsApi.get(this.id, callback, errorCallback);
		},
		fetchDetails() {
			this.isFetching = true;
			const callback = (response) => {
				const event = response.data;
				this.eventData = event;
				this.isFetching = false;
				this.setData();
			};
			const errorCallback = () => {
				this.isFetching = false;
			};
			eventsApi.get(this.invoice.event_id, callback, errorCallback);
		},
		dateFormat(date) {
			return dateFormat(date, 'DD. MM. YYYY HH:mm');
		},
		priceEvent(price) {
			if (price <= 0) {
				return 'Free';
			}
			return price;
		},
		generateInitial(event) {
			const words = event ? event.name.split(' ') : 'AN';
			const initial = words.length > 1 ? `${words[0][0]}${words[1][0]}` : words[0][0];
			return initial;
		},
		seeMyEvent() {
			this.$router.push('/').catch(() => {});
		},
		openPreview() {
			const routeData = this.$router.resolve({ name: 'EventLive', params: { eventSlug: this.eventData.slug } });
			window.open(`${routeData.href}`, '_blank');
		},
	},
	created() {
		this.getInvoice();
	},
};
</script>

<style lang="scss">
.card-img-top-details {
	border-top-left-radius: .5rem;
	border-top-right-radius: .5rem;
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	min-height: 400px;
	max-height: 400px;
	object-fit: cover;
}
.avatar-col {
	width: 12rem;
}
@media screen and (min-width: 1201px) and (max-width: 1211px),
	only screen and (min-width: 636px) and (max-width: 991px) {
	#account-info-col-1 {
		width: calc(100% - 12rem) !important;
	}
}
.input-code .vs-con-input .vs-inputx {
	height: 50px!important;
	font-size:  22px!important;
	background-color: #ECECEC;
	border: 0px!important;
	color: black;
}
.event-user{
	margin-left: auto;
	margin-right: auto;
		max-width: 681px;
}s
.date-event{
	width: 100%;
}
.icon-calendar-event{
	color: grey;
	position: absolute;
	top: 0;
	margin: 0.3em;
	right: 0;
}
.editor-overview .ql-editor{
	min-height: 400px!important;
}
.title-user-events{
	font-size: 46px;
	font-weight: 100;
	color:	#363636;
}
.thumbnail-event{
	min-height: 400px;
	max-height: 400px;
	padding: 2em;
	padding-top: 6.5em;
	.initial-event {
	text-align: center;
	font-size: 7.5em;
	padding: 24px;
	font-weight: bold;
	max-width: 210px;
	margin: 0 auto;
	border-radius: 50%;
	background-color: #00a1ed;
	color: #fff;
	}
}
</style>
